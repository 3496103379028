<template>
    <!--begin::Price-->
    <div v-if="showPromo" class="ms-5">
        <div
            class="d-flex align-items-center justify-content-center"
            v-if="loading"
        >
            <div class="spinner-border spinner-border">
                <span class="visually-hidden"></span>
            </div>
        </div>
        <template v-else>
            <span class="mb-2 text-danger">{{ currencySymbol }}</span>
            <span
                class="fs-2x fw-bolder me-2 text-danger text-decoration-line-through"
                :data-kt-plan-price-month="monthlyPrice"
                :data-kt-plan-price-annual="yearlyPrice"
                >{{ switcher.monthly ? monthlyPrice : yearlyPrice }}</span
            >
            <span class="mb-2">{{ currencySymbol }}</span>
            <span
                class="fs-3x fw-bolder"
                data-kt-plan-price-month="29"
                data-kt-plan-price-annual="292"
                >{{ switcher.monthly ? monthlyPrice : yearlyPrice }}</span
            >
            <span class="fs-7 opacity-50"
                >/
                <span v-if="switcher.monthly" data-kt-element="period">{{
                    __('Mon')
                }}</span>
                <span v-else data-kt-element="period">{{ __('Ann') }}</span>
            </span>
        </template>
    </div>
    <!--end::Price-->
    <!--begin::Price-->
    <div v-else class="ms-5">
        <div
            class="d-flex align-items-center justify-content-center"
            v-if="loading"
        >
            <div class="spinner-border spinner-border">
                <span class="visually-hidden"></span>
            </div>
        </div>
        <template v-else>
            <span class="mb-2">{{ currencySymbol }}</span>
            <span
                class="fs-3x fw-bolder"
                :data-kt-plan-price-month="monthlyPrice"
                :data-kt-plan-price-annual="yearlyPrice"
                >{{ switcher.monthly ? monthlyPrice : yearlyPrice }}</span
            >
            <span class="fs-7 opacity-50"
                >/
                <span v-if="switcher.monthly" data-kt-element="period">{{
                    __('Mon')
                }}</span>
                <span v-else data-kt-element="period">{{ __('Ann') }}</span>
            </span>
        </template>
    </div>
    <!--end::Price-->
</template>

<script>
import axios from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map';

export default {
    props: {
        showPromo: {
            type: Boolean,
            default: false,
        },
        switcher: {
            monthly: {
                type: Boolean,
                default: true,
            },
            yearly: {
                type: Boolean,
                default: false,
            },
        },
        monthlyPlan: {
            type: String,
            required: true,
        },
        yearlyPlan: {
            type: String,
            required: true,
        },
        defaultMonthlyPrice: {
            type: String,
            default: '0',
        },
        defaultYearlyPrice: {
            type: String,
            default: '0',
        },
        defaultCurrencySymbol: {
            type: String,
            default: '$',
        },
        fontColor: {
            type: String,
            default: 'black',
        },
    },
    data() {
        return {
            currencySymbol: '',
            monthlyPrice: '',
            yearlyPrice: '',
            loading: true,
        };
    },
    mounted() {
        this.currencySymbol = this.defaultCurrencySymbol;
        this.monthlyPrice = this.defaultMonthlyPrice;
        this.yearlyPrice = this.defaultYearlyPrice;

        this.fetchPrices();
    },
    methods: {
        async fetchPrices() {
            try {
                // Fetching monthly price
                const monthlyResponse = await axios.get(
                    `/api/prices/get-plan-details?product_id=${this.monthlyPlan}`
                );
                const monthlyProductData =
                    monthlyResponse.data.response.products[0];
                if (
                    monthlyProductData &&
                    monthlyProductData.product_id.toString() ===
                        this.monthlyPlan
                ) {
                    this.monthlyPrice = monthlyProductData.price.net;
                }

                // Fetching yearly price
                const yearlyResponse = await axios.get(
                    `/api/prices/get-plan-details?product_id=${this.yearlyPlan}`
                );
                const yearlyProductData =
                    yearlyResponse.data.response.products[0];
                if (
                    yearlyProductData &&
                    yearlyProductData.product_id.toString() === this.yearlyPlan
                ) {
                    this.yearlyPrice = yearlyProductData.price.net;
                }

                // Setting the currency symbol (assumption is that both plans use the same currency)
                this.currencySymbol = monthlyProductData
                    ? getSymbolFromCurrency(monthlyProductData.currency)
                    : '$';
                this.loading = false;
            } catch (error) {
                this.loading = false;
                console.error('Failed to fetch product details:', error);
            }
        },
    },
};
</script>
