<template>
    <app-layout
        :title="__('Bulk processing')"
        :view="__('Files')"
        :user="$attrs.user"
    >
        <template #header>
            <h1 class="h4 font-weight-bold">
                {{ __('Bulk processing') }}
            </h1>
        </template>
        <div class="row gx-5 gx-xl-10 mb-10">
            <div class="col-12">
                <h1>{{ __('Bulk processing') }}</h1>
                <div class="text-muted fs-7 fw-bold">
                    {{
                        __('Here you can find all the files you have created.')
                    }}
                </div>
            </div>
        </div>
        <BulkFilesTable />
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import BulkFilesTable from '@/Components/Bulk/BulkFilesTable.vue';

export default defineComponent({
    components: {
        AppLayout,
        BulkFilesTable,
    },
});
</script>
