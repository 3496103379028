<template>
    <!--begin::Modal - New Project-->
    <div
        class="modal fade"
        id="kt_modal_choose_plan"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop="static"
        :data-bs-backdrop="
            subscriptionStore.hasNoSubscription ? 'static' : true
        "
        :data-bs-keyboard="!subscriptionStore.hasNoSubscription"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <!--begin::Modal content-->
            <div class="modal-content rounded">
                <!--begin::Modal header-->
                <div class="modal-header pb-0 border-0 justify-content-end">
                    <!--begin::Close-->
                    <div
                        v-if="!subscriptionStore.hasNoSubscription"
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                    <a
                        v-else
                        href="#"
                        class="btn btn-sm btn-white border border-1"
                        @click.prevent.stop="logout"
                        >{{ __('Log out') }}</a
                    >
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body pt-0 pb-15 px-5 px-xl-20">
                    <!--begin::Heading-->
                    <div class="mb-5 text-center">
                        <h1 class="mb-3">{{ __('Choose a Plan') }}</h1>
                    </div>
                    <div v-if="showPromo" class="d-flex flex-column mb-5">
                        <div class="d-flex flex-grow-1 m-2 mx-auto px-2">
                            <!--begin::Wrapper-->
                            <div class="row align-items-center ps-2">
                                <div class="d-flex fs-fluid col m-0">
                                    <span
                                        class="text-danger fw-bolder fs-2 mx-4"
                                        >{{
                                            __('60% Easter Saving Promotion!')
                                        }}</span
                                    >
                                </div>
                            </div>
                            <!--end::Wrapper-->
                        </div>
                    </div>
                    <!--end::Heading-->
                    <!--begin::Plans-->
                    <div class="d-flex flex-column">
                        <!--begin::Nav group-->
                        <div
                            class="nav-group nav-group-outline mx-auto"
                            data-kt-buttons="true"
                        >
                            <a
                                href="#"
                                class="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2"
                                :class="{ active: switcher.monthly }"
                                @click="toggleSwitcher"
                                data-kt-plan="month"
                                >{{ __('Monthly') }}</a
                            >
                            <a
                                href="#"
                                class="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3"
                                :class="{ active: switcher.yearly }"
                                @click="toggleSwitcher"
                                data-kt-plan="annual"
                                >{{ __('Annual') }}</a
                            >
                        </div>
                        <!--end::Nav group-->
                        <div class="text-center mt-2">
                            <span class="fw-bolder text-gray-500">{{
                                __('*First 7 days free')
                            }}</span>
                        </div>
                        <!--begin::Row-->
                        <div class="row mt-10">
                            <!--begin::Col-->
                            <div class="col-lg-6 mb-10 mb-lg-0">
                                <!--begin::Tabs-->
                                <div class="nav flex-column">
                                    <!--begin::Tab link-->
                                    <div
                                        class="nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 mb-6"
                                        @click="selectPlan('basic')"
                                        data-bs-toggle="tab"
                                        data-bs-target="#kt_upgrade_plan_startup"
                                    >
                                        <!--end::Description-->
                                        <div
                                            class="d-flex align-items-center me-2"
                                        >
                                            <!--begin::Radio-->
                                            <div
                                                class="form-check form-check-custom form-check-solid form-check-success me-6"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="plan"
                                                    value="basic"
                                                    v-model="plan"
                                                />
                                            </div>
                                            <!--end::Radio-->
                                            <!--begin::Info-->
                                            <div class="flex-grow-1">
                                                <span
                                                    class="d-flex align-items-center fs-2 fw-bolder flex-wrap"
                                                    >{{ __('Basic') }}</span
                                                >
                                                <span
                                                    class="fw-bold opacity-60"
                                                    >{{
                                                        __(
                                                            'The perfect plan to start.'
                                                        )
                                                    }}</span
                                                >
                                            </div>
                                            <!--end::Info-->
                                        </div>
                                        <!--end::Description-->
                                        <PaddlePrice
                                            v-if="showPrice"
                                            :show-promo="showPromo"
                                            :switcher="switcher"
                                            :monthly-plan="
                                                $page.props.plans.basic.monthly
                                            "
                                            :yearly-plan="
                                                $page.props.plans.basic.yearly
                                            "
                                            default-monthly-price="19"
                                            default-yearly-price="199"
                                        ></PaddlePrice>
                                    </div>
                                    <!--end::Tab link-->
                                    <!--begin::Tab link-->
                                    <div
                                        class="nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary active d-flex flex-stack text-start p-6 mb-6"
                                        @click="selectPlan('standard')"
                                        data-bs-toggle="tab"
                                        data-bs-target="#kt_upgrade_plan_advanced"
                                    >
                                        <!--end::Description-->
                                        <div
                                            class="d-flex align-items-center me-2"
                                        >
                                            <!--begin::Radio-->
                                            <div
                                                class="form-check form-check-custom form-check-solid form-check-success me-6"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="plan"
                                                    checked="checked"
                                                    value="standard"
                                                    v-model="plan"
                                                />
                                            </div>
                                            <!--end::Radio-->
                                            <!--begin::Info-->
                                            <div class="flex-grow-1">
                                                <span
                                                    class="d-flex align-items-center fs-2 fw-bolder flex-wrap"
                                                    >{{ __('Standard') }}
                                                    <span
                                                        class="badge badge-light-success ms-2 fs-7"
                                                        >{{
                                                            __('Most popular')
                                                        }}</span
                                                    ></span
                                                >
                                                <span
                                                    class="fw-bold opacity-60"
                                                    >{{
                                                        __('For regular usage.')
                                                    }}</span
                                                >
                                            </div>
                                            <!--end::Info-->
                                        </div>
                                        <!--end::Description-->
                                        <PaddlePrice
                                            v-if="showPrice"
                                            :show-promo="showPromo"
                                            :switcher="switcher"
                                            :monthly-plan="
                                                $page.props.plans.standard
                                                    .monthly
                                            "
                                            :yearly-plan="
                                                $page.props.plans.standard
                                                    .yearly
                                            "
                                            default-monthly-price="49"
                                            default-yearly-price="499"
                                        ></PaddlePrice>
                                    </div>
                                    <!--end::Tab link-->
                                    <!--begin::Tab link-->
                                    <div
                                        class="nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 mb-6"
                                        @click="selectPlan('professional')"
                                        data-bs-toggle="tab"
                                        data-bs-target="#kt_upgrade_plan_enterprise"
                                    >
                                        <!--end::Description-->
                                        <div
                                            class="d-flex align-items-center me-2"
                                        >
                                            <!--begin::Radio-->
                                            <div
                                                class="form-check form-check-custom form-check-solid form-check-success me-6"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="plan"
                                                    value="professional"
                                                    v-model="plan"
                                                />
                                            </div>
                                            <!--end::Radio-->
                                            <!--begin::Info-->
                                            <div class="flex-grow-1">
                                                <span
                                                    class="d-flex align-items-center fs-2 fw-bolder flex-wrap"
                                                    >{{
                                                        __('Professional')
                                                    }}</span
                                                >
                                                <span
                                                    class="fw-bold opacity-60"
                                                    >{{
                                                        __(
                                                            "You're a pro and you need more."
                                                        )
                                                    }}</span
                                                >
                                            </div>
                                            <!--end::Info-->
                                        </div>
                                        <!--end::Description-->
                                        <PaddlePrice
                                            v-if="showPrice"
                                            :show-promo="showPromo"
                                            :switcher="switcher"
                                            :monthly-plan="
                                                $page.props.plans.professional
                                                    .monthly
                                            "
                                            :yearly-plan="
                                                $page.props.plans.professional
                                                    .yearly
                                            "
                                            default-monthly-price="99"
                                            default-yearly-price="999"
                                        ></PaddlePrice>
                                    </div>
                                    <!--end::Tab link-->
                                    <!--begin::Tab link-->
                                    <div
                                        class="nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6"
                                    >
                                        <!--end::Description-->
                                        <div
                                            class="d-flex align-items-center me-2"
                                        >
                                            <!--begin::Radio-->
                                            <div
                                                class="form-check form-check-custom form-check-solid form-check-success me-6"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="plan"
                                                    value="custom"
                                                />
                                            </div>
                                            <!--end::Radio-->
                                            <!--begin::Info-->
                                            <div class="flex-grow-1">
                                                <span
                                                    class="d-flex align-items-center fs-2 fw-bolder flex-wrap"
                                                    >Custom</span
                                                >
                                                <span
                                                    class="fw-bold opacity-60"
                                                    >{{
                                                        __(
                                                            'You need more? Get in touch with us.'
                                                        )
                                                    }}</span
                                                >
                                            </div>
                                            <!--end::Info-->
                                        </div>
                                        <!--end::Description-->
                                        <!--begin::Price-->
                                        <div class="ms-5">
                                            <a
                                                href="mailto:contact@creaitor.ai"
                                                class="btn btn-sm btn-primary"
                                                >{{ __('Contact Us') }}</a
                                            >
                                        </div>
                                        <!--end::Price-->
                                    </div>
                                    <!--end::Tab link-->
                                </div>
                                <!--end::Tabs-->
                            </div>
                            <!--end::Col-->
                            <!--begin::Col-->
                            <div class="col-lg-6">
                                <!--begin::Tab content-->
                                <div
                                    class="tab-content rounded h-100 bg-light p-10"
                                >
                                    <!--begin::Tab Pane-->
                                    <div
                                        class="tab-pane fade"
                                        id="kt_upgrade_plan_startup"
                                    >
                                        <!--begin::Heading-->
                                        <div class="pb-5">
                                            <h2 class="fw-bolder text-dark">
                                                {{
                                                    __('What’s in Basic Plan?')
                                                }}
                                            </h2>
                                            <div class="text-muted fw-bold">
                                                {{
                                                    __('Optimal for the start')
                                                }}
                                            </div>
                                        </div>
                                        <!--end::Heading-->
                                        <!--begin::Body-->
                                        <div class="pt-1">
                                            <!--begin::Item-->
                                            <div
                                                class="d-flex align-items-center mb-7"
                                                v-for="(
                                                    feature, index
                                                ) in planFeatures.basic"
                                                :key="index"
                                            >
                                                <span
                                                    class="fw-bold fs-5 text-gray-700 flex-grow-1"
                                                >
                                                    {{ feature }}
                                                </span>
                                                <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                                                <span
                                                    class="svg-icon svg-icon-1 svg-icon-success"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <rect
                                                            opacity="0.3"
                                                            x="2"
                                                            y="2"
                                                            width="20"
                                                            height="20"
                                                            rx="10"
                                                            fill="black"
                                                        />
                                                        <path
                                                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </span>
                                                <!--end::Svg Icon-->
                                            </div>
                                            <!--end::Item-->
                                        </div>
                                        <!--end::Body-->
                                    </div>
                                    <!--end::Tab Pane-->
                                    <!--begin::Tab Pane-->
                                    <div
                                        class="tab-pane fade show active"
                                        id="kt_upgrade_plan_advanced"
                                    >
                                        <!--begin::Heading-->
                                        <div class="pb-5">
                                            <h2 class="fw-bolder text-dark">
                                                {{
                                                    __(
                                                        'What’s in Standard Plan?'
                                                    )
                                                }}
                                            </h2>
                                            <div class="text-muted fw-bold">
                                                {{
                                                    __(
                                                        'Optimal for normal usage'
                                                    )
                                                }}
                                            </div>
                                        </div>
                                        <!--end::Heading-->
                                        <!--begin::Body-->
                                        <div class="pt-1">
                                            <!--begin::Item-->
                                            <div
                                                class="d-flex align-items-center mb-7"
                                                v-for="(
                                                    feature, index
                                                ) in planFeatures.standard"
                                                :key="index"
                                            >
                                                <span
                                                    class="fw-bold fs-5 text-gray-700 flex-grow-1"
                                                >
                                                    {{ feature }}
                                                </span>
                                                <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                                                <span
                                                    class="svg-icon svg-icon-1 svg-icon-success"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <rect
                                                            opacity="0.3"
                                                            x="2"
                                                            y="2"
                                                            width="20"
                                                            height="20"
                                                            rx="10"
                                                            fill="black"
                                                        />
                                                        <path
                                                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </span>
                                                <!--end::Svg Icon-->
                                            </div>
                                            <!--end::Item-->
                                        </div>
                                        <!--end::Body-->
                                    </div>
                                    <!--end::Tab Pane-->
                                    <!--begin::Tab Pane-->
                                    <div
                                        class="tab-pane fade"
                                        id="kt_upgrade_plan_enterprise"
                                    >
                                        <!--begin::Heading-->
                                        <div class="pb-5">
                                            <h2 class="fw-bolder text-dark">
                                                {{
                                                    __(
                                                        'What’s in an Professional Plan?'
                                                    )
                                                }}
                                            </h2>
                                            <div class="text-muted fw-bold">
                                                {{
                                                    __(
                                                        'Optimal daily and high usage'
                                                    )
                                                }}
                                            </div>
                                        </div>
                                        <!--end::Heading-->
                                        <!--begin::Body-->
                                        <div class="pt-1">
                                            <!--begin::Item-->
                                            <div
                                                class="d-flex align-items-center mb-7"
                                                v-for="(
                                                    feature, index
                                                ) in planFeatures.professional"
                                                :key="index"
                                            >
                                                <span
                                                    class="fw-bold fs-5 text-gray-700 flex-grow-1"
                                                >
                                                    {{ feature }}
                                                </span>
                                                <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                                                <span
                                                    class="svg-icon svg-icon-1 svg-icon-success"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <rect
                                                            opacity="0.3"
                                                            x="2"
                                                            y="2"
                                                            width="20"
                                                            height="20"
                                                            rx="10"
                                                            fill="black"
                                                        />
                                                        <path
                                                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </span>
                                                <!--end::Svg Icon-->
                                            </div>
                                            <!--end::Item-->
                                        </div>
                                        <!--end::Body-->
                                    </div>
                                    <!--end::Tab Pane-->
                                </div>
                                <!--end::Tab content-->
                            </div>
                            <!--end::Col-->
                        </div>
                        <!--end::Row-->
                    </div>
                    <!--end::Plans-->

                    <!--begin::Actions-->
                    <div class="d-flex flex-center flex-row-fluid pt-6">
                        <button
                            v-if="!subscriptionStore.hasNoSubscription"
                            type="reset"
                            class="btn btn-light me-3"
                            data-bs-dismiss="modal"
                        >
                            {{ __('Cancel') }}
                        </button>
                        <button
                            v-if="
                                intention === 'subscribe' &&
                                this.user.current_team.subscriptions.length ===
                                    0
                            "
                            @click="subscribeToPlan"
                            :disabled="isSubmitting"
                            type="submit"
                            class="btn btn-primary"
                        >
                            {{
                                isSubmitting
                                    ? __('Processing...')
                                    : __('Start Free Trial')
                            }}
                        </button>
                        <button
                            v-else-if="
                                intention === 'subscribe' &&
                                this.user.current_team.subscriptions.length > 0
                            "
                            @click="subscribeToPlan"
                            type="submit"
                            class="btn btn-primary"
                        >
                            {{
                                isSubmitting
                                    ? __('Processing...')
                                    : __('Choose Plan')
                            }}
                        </button>
                        <button
                            v-else
                            @click="switchToPlan"
                            type="submit"
                            class="btn btn-primary"
                        >
                            {{ __('Switch to Plan') }}
                        </button>
                    </div>
                    <!--end::Actions-->
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import { router as Inertia } from '@inertiajs/vue3';
import { Link } from '@inertiajs/vue3';
import PaddlePrice from '../Components/Modals/PaddlePrice.vue';
import { Modal } from 'bootstrap';
import { useUserStore } from '@/Stores/UserStore';
import { useTeamStore } from '@/Stores/TeamStore';
import { useSubscriptionStore } from '@/Stores/SubscriptionStore';
import { useToast } from 'vue-toastification';

export default {
    props: {
        user: Object,
    },
    components: {
        Link,
        PaddlePrice,
    },
    setup() {
        // Get user store
        const userStore = useUserStore();
        // Get team store
        const teamStore = useTeamStore();
        // Get subscription store
        const subscriptionStore = useSubscriptionStore();

        const toast = useToast();

        // Make it available inside methods
        return {
            userStore,
            teamStore,
            subscriptionStore,
            toast,
        };
    },
    data() {
        return {
            intention: 'subscribe',
            switcher: {
                monthly: true,
                yearly: false,
            },
            selectingNewPlan: false,
            plan: 'standard',
            currentPlan: null,
            billableId: this.user.current_team_id,
            billableType: 'team',
            modal: {},
            loading: false,
            promoDuration: 0,
            isSubmitting: false,
            planFeatures: {
                basic: [
                    this.__('300,000 Characters per month'),
                    this.__('1 User'),
                    this.__('20 AI Images per month'),
                    this.__('80+ AI Assistants'),
                    this.__('40+ Languages'),
                    this.__('AI Chat'),
                    this.__('Unlimited Projects & Documents'),
                ],
                standard: [
                    this.__('Unlimited characters'),
                    this.__('40 AI Images'),
                    this.__('1 Keyword Research credits'),
                    this.__('1 SERP Beater Articles'),
                    this.__('Advanced SEO Scoring'),
                    this.__('Translation Form'),
                    this.__('SEO capabilites'),
                ],
                professional: [
                    this.__('1 Users included'),
                    this.__('1 Brand Identities'),
                    this.__('60 AI Images'),
                    this.__('3 Keyword Research credits'),
                    this.__('3 SERP Beater Articles'),
                    this.__('SEO Rank Tracking (coming soon)'),
                    this.__('Plagiarism Checker (coming soon)'),
                    this.__('API Access'),
                    this.__('Bulk Processing'),
                    this.__('Priority Support'),
                ],
            },
            showPrice: false,
        };
    },
    mounted() {
        let vm = this;

        vm.modal = new Modal(document.getElementById('kt_modal_choose_plan'));

        setTimeout(() => {
            if (
                vm.subscriptionStore.hasNoSubscription &&
                vm.userStore?.getUser?.current_project_id
            ) {
                vm.openModal();

                // eslint-disable-next-line no-undef
                emitter.emit('open-initial-choose-plan-finished');
            }
        }, 500);

        emitter.on('open-choose-plan', function (data) {
            vm.openModal();
            vm.intention = data ? data.intention : 'subscribe';
            vm.currentPlan = data ? parseInt(data.currentPlan) : 23634;
        });

        emitter.on('close-choose-plan', function () {
            vm.modal.hide();
        });

        emitter.on('update-promo-duration', function (ev) {
            vm.promoDuration = ev.duration;
        });

        this.userStore.setUser(this.user);
        this.subscriptionStore.setPlans(this.getPlans() || {});

        this.isPaddleBillingType;

        if (this.isPaddleBillingType) {
            const script = document.createElement('script');
            script.src = 'https://cdn.paddle.com/paddle/v2/paddle.js';
            script.async = true;
            script.onload = () => {
                this.setUpPaddleOption();
            };

            document.head.appendChild(script);
        }
    },
    methods: {
        openModal() {
            this.showPrice = true;
            this.modal.show();
        },
        setUpPaddleOption() {
            const paddleVendorId = parseInt(
                import.meta.env.VITE_PADDLE_VENDOR_ID,
                10
            );
            let setupOptions = {
                seller: parseInt(import.meta.env.VITE_PADDLE_VENDOR_ID, 10),
            };

            let vm = this;

            setupOptions.eventCallback = function (event) {
                switch (event.name) {
                    case 'checkout.closed':
                        vm.isSubmitting = false;

                        window.history.pushState(
                            {},
                            document.title,
                            window.location.pathname
                        );

                        break;
                    case 'checkout.completed':
                        let orderAmount =
                            event.data.totals.total - event.data.totals.tax;
                        vm.gtmTrackEvent(orderAmount);

                        vm.$page.props.state = 'pending';

                        window.history.pushState(
                            {},
                            document.title,
                            window.location.pathname
                        );

                        window.fpr('referral', {
                            event_id: event?.data?.id,
                            email: event?.data?.customer?.email,
                            uid: event?.data?.customer?.id,
                            amount: orderAmount * 100,
                            plan: event?.data?.items[0]?.product?.id,
                        });

                        window.lintrk('track', { conversion_id: 13768602 });
                        vm.request('POST', '/api/billing/pending-checkout', {
                            checkout_id: event.data.id,
                        })
                            .then(() => {
                                setTimeout(() => {
                                    vm.reloadData();
                                    vm.isSubmitting = false;
                                    vm.modal.hide();
                                    Paddle.Checkout.close();

                                    Intercom('trackEvent', 'new-subscriber');
                                }, 3000);
                            })
                            .catch((error) => {
                                // Handle any errors from your server
                                console.error(
                                    'Error in processing checkout:',
                                    error
                                );
                            });

                        break;
                }
            };

            Paddle.Setup(setupOptions);

            if (this.$page.props.paddle_sandbox) {
                Paddle.Environment.set('sandbox');
            }
        },
        getPlanPrices(plan) {
            let prices = this.$page.props.plans[plan];

            return {
                monthly: prices.monthly,
                yearly: prices.yearly,
            };
        },

        /**
         * Subscribe to the given plan.
         */
        subscribeToPlan() {
            let vm = this;
            this.isSubmitting = true;
            Paddle.Spinner.show();

            window.history.pushState(
                {},
                document.title,
                window.location.pathname + '?subscribe=' + this.paddlePlan
            );

            this.request('POST', '/api/billing/subscription', {
                plan: this.paddlePlan,
            })
                .then((response) => {
                    if (vm.isPaddleBillingType) {
                        vm.openPaddleBillingCheckout(response);
                    } else {
                        vm.openPaddleClassicCheckout(response);
                    }
                })
                .catch(() => {
                    vm.toast.error(vm.errors);
                });
        },
        openPaddleBillingCheckout(response) {
            const checkout = response.data.checkout;

            Paddle.Checkout.open({
                items: checkout.items,
                customer: checkout.customer,
                customData: checkout.custom_data,
                settings: {
                    allowLogout: false,
                    displayMode: 'overlay',
                },
            });
        },
        openPaddleClassicCheckout(response) {
            let vm = this;

            if (this.$page.props.paddle_sandbox) {
                Paddle.Environment.set('sandbox');
            }

            Paddle.Checkout.open({
                override: response.data.link,
                disableLogout: true,
                /*coupon: "SPRINGBEGIN22",*/
                successCallback: (response) => {
                    let orderAmount = (
                        response.checkout.prices.vendor.total -
                        response.checkout.prices.vendor.total_tax
                    ).toFixed(2);

                    vm.gtmTrackEvent(orderAmount);

                    vm.$page.props.state = 'pending';

                    window.history.pushState(
                        {},
                        document.title,
                        window.location.pathname
                    );

                    this.$posthog.capture('subscribe_to_trial', {
                        user_id: this.user.id,
                        trial_start_date: new Date().toISOString(),
                    });

                    vm.trackConversion(response);
                    window.lintrk('track', { conversion_id: 13768602 });

                    // Send data to your server and wait for the response
                    vm.request('POST', '/spark/pending-checkout', {
                        checkout_id: response.checkout.id,
                    })
                        .then(() => {
                            setTimeout(() => {
                                vm.reloadData();
                                vm.isSubmitting = false;
                                vm.modal.hide();

                                Intercom('trackEvent', 'new-subscriber');
                            }, 3000);
                        })
                        .catch((error) => {
                            // Handle any errors from your server
                            console.error(
                                'Error in processing checkout:',
                                error
                            );
                        });
                },
            });
        },
        gtmTrackEvent(orderAmount) {
            this.$gtm.trackEvent({
                userId: this.user.id,
                event: 'subscribe-free-trial',
                category: 'customer',
                action: 'subscribed',
                label: 'Customer has subscribed to free trial',
                value: orderAmount,
            });
        },
        trackConversion(data) {
            let order_amount = (
                data.checkout.prices.vendor.total -
                data.checkout.prices.vendor.total_tax
            ).toFixed(2);

            window.fpr('referral', {
                event_id: data.checkout.id,
                email: data.user.email,
                uid: data.user.id,
                amount: order_amount * 100,
                plan: data.product.id,
            });
        },

        /**
         * Switch to the given plan.
         */
        switchToPlan() {
            Paddle.Spinner.show();

            this.request('PUT', '/api/spark/subscription', {
                plan: this.paddlePlan,
            }).then((response) => {
                this.reloadData();
                this.modal.hide();
            });
        },

        /**
         * Make an outgoing request to the Laravel application.
         */
        request(method, url, data = {}) {
            this.errors = [];

            data.billableType = this.billableType.toString();
            data.billableId = this.billableId.toString();

            return axios
                .request({
                    url: url,
                    method: method,
                    data: data,
                })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    Paddle.Spinner.hide();

                    if (error.response.status == 422) {
                        this.errors = _.flatMap(error.response.data.errors);
                    } else if (error.response.status == 400) {
                        this.errors = error.response.data.message;
                    } else {
                        this.errors = [
                            'An unexpected error occurred and we have notified our support team. Please try again later.',
                        ];
                    }
                });
        },

        /**
         * Start periodically reloading the page's data.
         */
        startReloadingData() {
            setTimeout(() => {
                this.reloadData();
            }, 2000);
        },

        /**
         * Reload the page's data, while maintaining any current state.
         */
        reloadData() {
            let vm = this;
            emitter.emit('reload-image-usage-card');
            return this.$inertia.reload({
                onSuccess: () => {
                    if (this.$page.props.state == 'pending') {
                        this.startReloadingData();
                    }

                    if (this.selectingNewPlan) {
                        this.selectingNewPlan = false;
                    }

                    Paddle.Spinner.hide();
                },
            });
        },

        getCookie(c_name) {
            if (document.cookie.length > 0) {
                let c_start = document.cookie.indexOf(c_name + '=');
                if (c_start != -1) {
                    c_start = c_start + c_name.length + 1;
                    let c_end = document.cookie.indexOf(';', c_start);
                    if (c_end == -1) {
                        c_end = document.cookie.length;
                    }
                    return unescape(document.cookie.substring(c_start, c_end));
                }
            }
            return null;
        },

        closeModal() {
            this.modal.hide();
        },

        toggleSwitcher() {
            this.switcher.monthly = !this.switcher.monthly;
            this.switcher.yearly = !this.switcher.yearly;
        },

        selectPlan(plan) {
            this.plan = plan;
        },

        logout() {
            this.$inertia.post('/logout');
            this.closeModal();
        },
    },
    computed: {
        currentPeriod() {
            return this.switcher.monthly ? 'monthly' : 'yearly';
        },
        paddlePlan() {
            let plan = this.showPromo ? this.plan + '_promo' : this.plan;
            plan = this.isPaddleBillingType ? 'billing_' + plan : plan;

            return this.$page.props.plans[plan][this.currentPeriod];
        },
        isPaddleBillingType() {
            return paddleType == 'billing';
        },
        showPromo() {
            return this.promoDuration > 0;
        },
    },
};
</script>
