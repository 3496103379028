<template>
    <div
        class="modal fade"
        id="kt_serp_beater_wizard_modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="kt_serp_beater_wizard_modal"
        aria-hidden="true"
        ref="serpBeaterModal"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-fullscreen">
            <!--begin::Modal content-->
            <div class="modal-content bg-gray-100">
                <!--begin::Modal header-->
                <div class="modal-header bg-white">
                    <!--begin::Heading-->
                    <h2>{{ __('SERP Beater Wizard') }}</h2>
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body serp-beater-wizard m-5 mx-0 my-0">
                    <HorizontalWizard
                        :user="user"
                        :stepDetails="stepDetails"
                        :step="articleWizardStore.getCurrentStep"
                        :loading="loading"
                        :finishedSteps="articleWizardStore.getFinishedSteps"
                        :stepNumber="stepNumber"
                        :stepNaviagationIcon="stepNaviagationIcon"
                        :headerFullwidth="headerFullwidth"
                        :contentFullwidth="contentFullwidth"
                        :can-proceed="canProceed"
                        @horizontal-wizard-next-step="nextStep"
                        @horizontal-wizard-previous-step="previousStep"
                        @horizontal-wizard-submit="wizardSubmit"
                    >
                        <template v-slot:tabs>
                            <div
                                v-if="!showCurrentStepInFullWidth"
                                class="step-content-left w-50 h-100 pr-5"
                            >
                                <div
                                    class="step-content-inner fv-plugins-bootstrap5 fv-plugins-framework bg-white"
                                >
                                    <div
                                        v-if="
                                            articleWizardStore.getCurrentStep === 0
                                        "
                                    >
                                        <Context
                                            :contextText="contextText"
                                            :showFocusKeyword="true"
                                            :showSearchEngine="true"
                                            @context-text-update="
                                                onContextTextUpdate
                                            "
                                            @language-change="onLanguageChange"
                                            @formality-change="
                                                onFormalityChange
                                            "
                                            @focus-keyword-update="
                                                onFocusKeywordUpdate
                                            "
                                            @article-context-search-engine-or-language-changed="
                                                articleContextSearchEngineORLanguageChanged(
                                                    $event
                                                )
                                            "
                                        >
                                        </Context>
                                    </div>

                                    <div
                                        v-if="
                                            articleWizardStore.getCurrentStep === 2
                                        "
                                    >
                                        <h2 class="text-primary">
                                            {{ __('Title') }}
                                            <span>*</span>
                                        </h2>
                                        <p class="text-muted">
                                            {{
                                                __(
                                                    'Pick a suitable title for your article'
                                                )
                                            }}
                                        </p>
                                        <TitleSelection></TitleSelection>
                                    </div>

                                    <div
                                        v-if="
                                            articleWizardStore.getCurrentStep === 3
                                        "
                                        class="outlines-selected card h-100"
                                    >
                                        <OutlineSelection></OutlineSelection>
                                    </div>

                                    <div
                                        v-if="
                                            articleWizardStore.getCurrentStep === 4
                                        "
                                    >
                                        <h2 class="text-primary">
                                            {{ __('Writing Points') }}
                                        </h2>
                                        <p class="text-muted">
                                            {{
                                                __(
                                                    'Put together build blocks of your article by choosing writing points for each heading'
                                                )
                                            }}
                                        </p>
                                        <WritingPoints></WritingPoints>
                                    </div>

                                    <div
                                        v-if="
                                            articleWizardStore.getCurrentStep === 5
                                        "
                                    >
                                        <h2 class="text-primary">
                                            {{ __('First Draft') }}
                                        </h2>
                                        <p class="text-muted">
                                            {{
                                                __(
                                                    "Here is your article's first draft! Make changes as you like"
                                                )
                                            }}
                                        </p>
                                        <FirstDraftLeftPanel></FirstDraftLeftPanel>
                                    </div>

                                    <!-- Slot for result ends-->
                                </div>
                            </div>
                            <div
                                class="step-content-right ps-0 h-100 pl-5"
                                :class="[
                                    showCurrentStepInFullWidth
                                        ? 'w-100'
                                        : 'w-50',
                                ]"
                            >
                                <!--begin::Form-->

                                <div
                                    class="step-content-inner fv-plugins-bootstrap5 fv-plugins-framework bg-white"
                                    v-if="showRightPanel"
                                >
                                    <!-- Slot for tab contents begins-->

                                    <!--begin::Step 1-->
                                    <div
                                        v-if="
                                            articleWizardStore.getCurrentStep === 1
                                        "
                                        :class="[
                                            articleWizardStore.getCurrentStep === 1
                                                ? 'current'
                                                : 'pending',
                                        ]"
                                        class="h-100"
                                        data-kt-stepper-element="content"
                                    >
                                        <Competitors
                                            :user="user"
                                            @update-loading-state="
                                                loading = $event
                                            "
                                        ></Competitors>
                                    </div>
                                    <!--end::Step 1-->

                                    <!--begin::Step 2-->
                                    <div
                                        v-if="
                                            articleWizardStore.getCurrentStep === 2
                                        "
                                        class="current"
                                        data-kt-stepper-element="content"
                                    >
                                        <Title
                                            :aiGeneratedTitles="
                                                articleWizardStore.aiGeneratedTitles
                                            "
                                            @article-title-selected="
                                                selectedArticleTitle = $event
                                            "
                                        ></Title>
                                    </div>
                                    <!--end::Step 2-->

                                    <!--begin::Step 3-->
                                    <div
                                        v-if="
                                            articleWizardStore.getCurrentStep === 3
                                        "
                                        class="current outlines-listing card h-100"
                                        data-kt-stepper-element="content"
                                    >
                                        <Outline :user="user"></Outline>
                                    </div>
                                    <!--end::Step 3-->

                                    <!--begin::Step 5-->
                                    <div
                                        v-if="
                                            articleWizardStore.getCurrentStep === 5
                                        "
                                        class="current"
                                        data-kt-stepper-element="content"
                                    >
                                        <FirstDraft
                                            :lang="articleWizardStore.getLanguage"
                                            :formality="
                                                articleWizardStore.getFormality
                                            "
                                            @loading-changed="loading = $event"
                                        >
                                        </FirstDraft>
                                    </div>
                                    <!--end::Step 5-->
                                </div>
                                <!--end::Form-->
                            </div>
                        </template>
                    </HorizontalWizard>
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import {router as Inertia} from '@inertiajs/vue3';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import HorizontalWizard from '@/Components/Wizard/HorizontalWizard.vue';
import Context from '@/Layouts/Article/Partials/Wizard/Guided/Context.vue';
import TitleSelection from '@//Layouts/Article/Partials/Wizard/Guided/Partials/TitleSelection.vue';
import Competitors from '@/Layouts/Article/Partials/Wizard/SerpBeater/Competitors.vue';
import Title from '@/Layouts/Article/Partials/Wizard/Guided/Title.vue';
import OutlineSelection from '@/Layouts/Article/Partials/Wizard/Guided/OutlineSelection.vue';
import Outline from '@/Layouts/Article/Partials/Wizard/SerpBeater/Outline.vue';
import WritingPoints from '@/Layouts/Article/Partials/Wizard/Guided/WritingPoints.vue';
import FirstDraft from '@//Layouts/Article/Partials/Wizard/Guided/FirstDraft.vue';
import FirstDraftLeftPanel from '@//Layouts/Article/Partials/Wizard/Guided/FirstDraftLeftPanel.vue';
import { useToast } from 'vue-toastification';
import MarkdownIt from 'markdown-it';
import trialLimitExhausted from '@/Mixins/trialLimitExhausted';
import { Modal } from 'bootstrap';
const md = new MarkdownIt();

export default {
    mixins: [trialLimitExhausted],
    setup() {
        // Get article wizard store.
        const articleWizardStore = useArticleWizardStore();

        // Get toast interface
        const toast = useToast();
        // Make it available inside methods

        return { articleWizardStore, toast };
    },

    props: {
        user: Object,
    },

    components: {
        HorizontalWizard,
        Context,
        TitleSelection,
        Competitors,
        Title,
        OutlineSelection,
        Outline,
        WritingPoints,
        FirstDraft,
        FirstDraftLeftPanel,
    },

    data() {
        return {
            modal: {},
            stepNumber: true,
            stepNaviagationIcon: true,
            headerFullwidth: true,
            contentFullwidth: true,
            stepDetails: [
                {
                    title: this.__('Context'),
                },
                {
                    title: this.__('Competitors'),
                },
                {
                    title: this.__('Title'),
                },
                {
                    title: this.__('Outline'),
                },
                {
                    title: this.__('Writing Points'),
                },
                {
                    title: this.__('First Draft'),
                },
            ],
            contextText: '',
            selectedArticleTitle: '',
            aiGeneratedArticleBodies: [],
            selectedArticleBody: '',
            createDocumentWhileSerpCompetitorSelection: false,
        };
    },

    mounted() {
        this.$refs.serpBeaterModal.addEventListener(
            'hidden.bs.modal',
            this.handleModalHidden
        );

        this.modal = new Modal(this.$refs.serpBeaterModal);

        let vm = this;

        emitter.on('opened-serp-beater-wizard-modal', async (ev) => {
            if (ev.action === 'continue') {
                vm.serpBeaterUuid = ev.uuid;
                await vm.fetchSerpBeaterWizardData(vm.serpBeaterUuid);
            } else {
                // Only reset state for new wizards
                vm.articleWizardStore.resetState();
                vm.articleWizardStore.setSerpBeaterMode(true);

                if (ev.keyword) {
                    vm.articleWizardStore.setFocusKeyword(ev.keyword);
                }

                // For new wizards, ensure we start at step 0
                vm.articleWizardStore.setCurrentStep(0);
                vm.articleWizardStore.setFinishedStep(0);
            }

            vm.articleWizardStore.setIsModalOpen(true);
        });
    },

    beforeUnmount() {
        this.$refs.serpBeaterModal.removeEventListener(
            'hidden.bs.modal',
            this.handleModalHidden
        );
    },

    methods: {
        handleModalHidden() {
            this.articleWizardStore.setIsModalOpen(false);
        },

        async fetchSerpBeaterWizardData(serpBeaterUuid) {
            await this.articleWizardStore.loadStateFromDatabase(serpBeaterUuid);

            // After loading state, check if we have draft content and set the step accordingly
            if (this.articleWizardStore.getArticleDraftContent?.heading_lists?.length > 0) {
                this.articleWizardStore.setCurrentStep(5); // Set to First Draft step
                this.articleWizardStore.setFinishedStep(5);

                // Check if the wizard was completed
                if (this.articleWizardStore.getSerpBeater?.wizard_status === 'completed') {
                    // Set the wizard as completed
                    this.articleWizardStore.setWizardStatus('completed');
                }
            }
        },

        async nextStep() {
            if (this.articleWizardStore.getCurrentStep === 0) {
                if (
                    '' == this.articleWizardStore.getFocusKeyword
                ) {
                    return false;
                }

                await this.postSerpBeaters();
                this.completedStepCheck();

                return false;
            }

            if (this.articleWizardStore.getCurrentStep === 1) {
                if (
                    0 ===
                    this.articleWizardStore.getSelectedSerpBeaterCompetitorsId
                        .length
                ) {
                    return false;
                }

                await this.saveSerpBeaterSelections();
                this.completedStepCheck();

                return false;
            }

            if (this.articleWizardStore.getCurrentStep === 2) {
                if (!this.articleWizardStore.getArticleTitle) {
                    return false;
                }

                await this.fetchArticleOutlines();
                this.completedStepCheck();

                return false;
            }

            if (this.articleWizardStore.getCurrentStep === 3) {
                if (!this.articleWizardStore.getArticleOutline) {
                    return false;
                }

                //await this.fetchWritingPoints();

                if(this.articleWizardStore.getAiGeneratedWritingPoints.length === 0) {
                    let outlines = this.getArticleOutlineRequestFormat(
                        this.articleWizardStore.getArticleOutline
                    )

                    this.articleWizardStore.setAiGeneratedWritingPoints(
                        outlines
                    );
                }

                this.articleWizardStore.setCurrentStep(
                    this.articleWizardStore.getCurrentStep + 1
                );

                this.completedStepCheck();

                return false;
            }

            if (this.articleWizardStore.getCurrentStep === 4) {
                if (
                    this.articleWizardStore.getAiGeneratedWritingPoints.length ===
                    0
                ) {
                    return false;
                }

                this.articleWizardStore.setCurrentStep(
                    this.articleWizardStore.getCurrentStep + 1
                );
                this.completedStepCheck();

                return false;
            }
        },

        completedStepCheck() {
            if (
                this.articleWizardStore.getFinishedSteps <=
                this.articleWizardStore.getCurrentStep
            ) {
                this.articleWizardStore.getFinishedSteps =
                    this.articleWizardStore.getCurrentStep;
            }
        },

        previousStep() {
            if (4 === this.articleWizardStore.getCurrentStep) {
                this.articleWizardStore.setEmptyWritingPoints();
            }
            if (5 === this.articleWizardStore.getCurrentStep) {
                this.articleWizardStore.clearArticleDraftContentSection('introduction');
                this.articleWizardStore.clearArticleDraftContentSection('heading_lists');
                this.articleWizardStore.clearArticleDraftContentSection('faq_lists');
                this.articleWizardStore.clearArticleDraftContentSection('conclusion');
            }

            this.articleWizardStore.setCurrentStep(
                this.articleWizardStore.getCurrentStep - 1
            );
        },

        onContextTextUpdate(ev) {
            this.contextText = ev;
            this.articleWizardStore.setContext(ev);
        },

        onFocusKeywordUpdate(ev) {
            this.focusKeywordText = ev;
            this.articleWizardStore.setFocusKeyword(ev);
        },

        onLanguageChange(ev) {
            this.lang = ev;
            this.articleWizardStore.setLanguage(ev);
        },

        onFormalityChange(ev) {
            this.formality = ev;
            this.articleWizardStore.setFormality(ev);
        },

        articleContextSearchEngineORLanguageChanged(searchEngineSettings) {
            this.searchEngineSettings = searchEngineSettings;
            this.articleWizardStore.setSearchEngine(searchEngineSettings.engine);
            this.articleWizardStore.setLanguage(searchEngineSettings.language);
        },

        wizardSubmit() {
            this.loading = true;
            const draftContent = this.articleWizardStore?.getArticleDraftContent || {};
            const draftIntroduction = draftContent?.introduction || [];
            const draftHeadingContents = draftContent?.heading_lists || [];
            const draftFaqContents = draftContent?.faq_lists || [];
            const draftConclusion = draftContent?.conclusion || [];
            let htmlContent = '';

            htmlContent += this.articleWizardStore?.getArticleTitle
                ? `<h1>${this.articleWizardStore.getArticleTitle}</h1>`
                : '';

            draftIntroduction.forEach((introduction) => {
                htmlContent += md.render(introduction.content);
            });

            draftHeadingContents.forEach((headingContent) => {
                htmlContent += `<h2>${headingContent.heading}</h2>`;
                htmlContent += md.render(headingContent.content);

                const subHeadingContents = headingContent?.sub_headings || [];

                subHeadingContents?.forEach((subHeadingContent) => {
                    htmlContent += `<h3>${subHeadingContent.heading}</h3>`;
                    htmlContent += md.render(subHeadingContent.content);
                });
            });

            if (draftFaqContents.length > 0) {
                htmlContent += `<h2>${this.__('Frequently Asked Questions')}</h2>`;
                draftFaqContents.forEach((faq) => {
                    htmlContent += `<h2>${faq.heading}</h2>`;
                    htmlContent += md.render(faq.content);
                });
            }

            draftConclusion.forEach((conclusion) => {
                htmlContent += `<h2>${conclusion.heading}</h2>`;
                htmlContent += md.render(conclusion.content);
            });

            if (htmlContent) {
                this.saveArticleAsDocumentContent(htmlContent);
                // Set the wizard status as completed
                this.articleWizardStore.setWizardStatus('completed');
            }
        },
        async postSerpBeaters() {
            let vm = this;
            this.loading = true;
            let payload = {
                keyword: this.articleWizardStore.getFocusKeyword,
                locationCode: this.articleWizardStore.getSearchEngine,
                languageCode: this.articleWizardStore.getLanguage,
            };
            await axios
                .post('/api/serp-beaters', payload)
                .then((response) => {
                    vm.articleWizardStore.setSerpBeater(response.data);
                    Inertia.reload({ only: ['user'] });
                    vm.articleWizardStore.setCurrentStep(
                        vm.articleWizardStore.getCurrentStep + 1
                    );
                })
                .catch((error) => {
                    vm.toast.error(
                        error.response.data.message ||
                        vm.__('Some error occurred')
                    );
                    vm.loading = false;

                    if (
                        error.response.data.error_code ==
                        'trial_credit_limit_exhausted'
                    ) {
                        vm.modal.hide();
                        vm.showTrialLimitExhaustedPopUp();
                    }
                });
        },
        async saveSerpBeaterSelections() {
            let vm = this;
            this.loading = true;

            axios
                .post('/api/serp-beaters/save-selection', {
                    serpBeaterCompetitorIds:
                        this.articleWizardStore.getSelectedSerpBeaterCompetitorsId,
                    serpBeaterUuid: this.articleWizardStore.getSerpBeater.uuid,
                    create_document:
                        this.createDocumentWhileSerpCompetitorSelection,
                })
                .then((response) => {
                    Inertia.reload({ only: ['user'] });
                    vm.fetchArticleTitles();
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async fetchArticleTitles() {
            let vm = this;
            let options = this.articleWizardStore.getOpenAiOptions;

            let payload = {
                prompts: {
                    description: this.articleWizardStore.getFocusKeyword,
                    name: '',
                },
                options: { ...options, ...{ outputs: 8 } },
                lang: this.articleWizardStore.getLanguage,
                formality: this.articleWizardStore.getFormality,
                search_engine_settings: this.searchEngineSettings,
            };

            if (this.userTeamHasEnoughCredits || this.userHasUnlimitedUsage) {
                axios
                    .post('/api/article/ai-assistant/headline', payload)
                    .then((response) => {
                        vm.articleWizardStore.setAiGeneratedTitles(
                            JSON.parse(response.data.choices[0].text)
                        );
                        vm.completedStepCheck();
                        Inertia.reload({ only: ['user'] });
                        vm.articleWizardStore.setCurrentStep(
                            vm.articleWizardStore.getCurrentStep + 1
                        );
                    })
                    .catch(function (error) {
                        vm.toast.error(
                            error.response.data?.message ||
                                vm.__('Some error occurred')
                        );
                        if (
                            error.response.data.error_code ==
                            'trial_credit_limit_exhausted'
                        ) {
                            vm.showTrialLimitExhaustedPopUp();
                        }
                    })
                    .then(() => {
                        vm.loading = false;
                    });
            } else {
                this.toast.error(this.__('Your credits are over'));
                this.showTrialLimitExhaustedPopUp();
                this.loading = false;
            }
        },
        async fetchArticleOutlines() {
            let vm = this;
            this.loading = true;

            let payload = {
                prompts: {
                    description: this.articleWizardStore.getArticleTitle,
                    name: '',
                },
                options: { ...this.articleWizardStore.getOpenAiOptions },
                lang: this.articleWizardStore.getLanguage,
                formality: this.articleWizardStore.getFormality,
                headline: this.articleWizardStore.getArticleTitle,
                outline: this.articleWizardStore.getArticleOutline,
                keyword: this.articleWizardStore.getFocusKeyword,
            };

            vm.articleWizardStore.setArticleOutlineRequestPayload(payload);

            if (this.userTeamHasEnoughCredits || this.userHasUnlimitedUsage) {
                vm.articleWizardStore.setCurrentStep(
                    this.articleWizardStore.getCurrentStep + 1
                );
                vm.loading = false;
            } else {
                this.toast.error(this.__('Your credits are over'));
                this.showTrialLimitExhaustedPopUp();
                this.loading = false;
            }
        },
        getArticleOutlineRequestFormat(outline) {
            let headingStructure = outline?.headings?.map((mainHeading) => {
                let subHeadingsArray = mainHeading.subHeadings.map(subHeading => {
                    return {[subHeading.name]: []};
                });

                return {
                    heading_type_2: {
                        [mainHeading.name]: [],
                    },
                    heading_type_3: subHeadingsArray,
                    collapsed: false,
                };
            });

            let faqStructure = outline?.faqs?.map((faq) => {
                return {
                    heading_type_2: {
                        [faq.question]: [],
                    },
                    collapsed: false,
                };
            });

            return {
                heading_lists: headingStructure,
                faq_lists: faqStructure,
            };
        },
        saveArticleAsDocumentContent(htmlContent) {
            let data = {
                name: this.articleWizardStore.getArticleTitle,
                content: htmlContent,
                meta: {
                    title: '',
                    description: '',
                    domain: '',
                    slug: '',
                },
                keyword: this.articleWizardStore.getFocusKeyword,
                serp_beater_uuid: this.articleWizardStore.getSerpBeater?.uuid,
                create_document:
                    this.createDocumentWhileSerpCompetitorSelection,
            };

            axios
                .post('/api/article/serp-beater/save-document', data)
                .then((response) => {
                    let documentUrl = `${window.location.origin}/documents/${response.data?.document?.uuid}`;
                    this.articleWizardStore.resetState();
                    window.location.assign(documentUrl);
                })
                .catch((error) => {
                    this.toast.error(this.__('Some error occurred'));
                });
        },
    },
    computed: {
        loading: {
            get() {
                return this.articleWizardStore.isLoading;
            },
            set(value) {
                this.articleWizardStore.setLoading(value);
            },
        },
        showCurrentStepInFullWidth() {
            return [1].includes(this.articleWizardStore.getCurrentStep);
        },
        showRightPanel() {
            return [1, 2, 3, 5].includes(this.articleWizardStore.getCurrentStep);
        },
        userTeamHasEnoughCredits() {
            return (
                this.user.current_team.team_plan_credits.character_credits >
                    0 ||
                this.user.current_team.team_bonus_credits.character_credits > 0
            );
        },
        userHasUnlimitedUsage() {
            let user = this.user;
            let plans = this.getPlans();

            // Check if the user has a normal standard or professional subscription
            let hasNormalSubscription = user.current_team.subscriptions.some(
                (subscription) => {
                    const paddlePlanId = subscription.paddle_plan;
                    const professionalPlans = plans.professional;
                    const standardPlans = plans.standard;

                    return (
                        professionalPlans.includes(paddlePlanId) ||
                        standardPlans.includes(paddlePlanId)
                    );
                }
            );

            // Check if the user has a special lifetime subscription based on plan name or having more than one lifetime subscription
            let hasLifetimeSubscription =
                user.current_team.lifetime_subscriptions.some(
                    (subscription) => {
                        // Check if the plan name is "professional" or "standard"
                        if (
                            subscription.plan &&
                            ['professional', 'standard'].includes(
                                subscription.plan.toLowerCase()
                            )
                        ) {
                            return true;
                        } else if (
                            subscription.plan &&
                            ['basic'].includes(subscription.plan.toLowerCase())
                        ) {
                            // Check if the plan name is "basic"
                            return false;
                        }

                        // Check if the user has more than one lifetime subscription
                        return (
                            user.current_team.lifetime_subscriptions.length > 0
                        );
                    }
                );

            // Return true if the user has a normal subscription or a special lifetime subscription
            return hasNormalSubscription || hasLifetimeSubscription;
        },
        searchEngineSettings() {
            return {
                engine: this.articleWizardStore.getSearchEngine,
                language: this.articleWizardStore.getLanguage,
            };
        },
        canProceed() {
            if (this.articleWizardStore.getCurrentStep === 0) {
                return this.articleWizardStore?.getFocusKeyword?.length > 2;
            } else if (this.articleWizardStore.getCurrentStep === 1) {
                return (
                    this.articleWizardStore?.getSelectedSerpBeaterCompetitorsId
                        ?.length > 0
                );
            } else if (this.articleWizardStore.getCurrentStep === 2) {
                return this.articleWizardStore?.getArticleTitle !== '';
            } else if (this.articleWizardStore.getCurrentStep === 3) {
                const articleOutline =
                    this.articleWizardStore.getArticleOutline;

                return articleOutline?.headings?.length > 0;
            } else if (this.articleWizardStore.getCurrentStep === 4) {
                return (
                    this.articleWizardStore?.getAiGeneratedWritingPoints.length !==
                    0
                );
            } else if (this.articleWizardStore.getCurrentStep === 5) {
                return (
                    this.articleWizardStore?.getArticleDraftContent.heading_lists
                        .length !== 0
                );
            }

            return false;
        },
    },
};
</script>

<style lang="scss">
.serp-beater-wizard {
    .stepper {
        &.stepper-links {
            .stepper-nav {
                &.fullwidth {
                    .stepper-item {
                        &:first-child {
                            min-width: 13%;
                        }

                        &.current {
                            &:after {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }

    .outlines-selected {
        .list-group-item .list-group-item {
            border: none;
            background-color: transparent;

            &:hover,
            &:focus {
                background-color: transparent;
            }
        }

        .h3-element {
            margin-left: 10px;
        }

        .list-group-item + .list-group-item {
            border-top-width: 0;
        }

        .list-group-item {
            border-radius: 0;

            &:hover,
            &:focus {
                background-color: rgba(54, 153, 255, 0.1);
            }
        }
    }

    .title-selected {
        .sortable-ghost {
            display: none !important;
        }
    }

    .outline-elements {
        .h2-element {
            margin-left: 10px;

            span {
                &.arrow-up {
                    float: right;

                    i {
                        color: var(--bs-link-color);
                    }
                }
            }
        }

        .h3-element {
            margin-left: 10px;
        }

        .list-group-item {
            border-radius: 0;

            .add-action {
                span {
                    &.arrow-left {
                        i {
                            color: transparent;
                        }
                    }
                }
            }

            &:hover,
            &:focus {
                background-color: rgba(54, 153, 255, 0.1);

                > div > .add-action {
                    span {
                        &.arrow-left {
                            i {
                                color: var(--bs-link-color);
                            }
                        }
                    }
                }
            }
        }
    }

    .h3-element-wrapper {
        &:hover {
            .btn-remove {
                display: block !important;
            }
        }
    }

    span {
        &.arrow-up {
            float: right;

            i {
                color: var(--bs-link-color);
            }
        }
    }
}
</style>
