<template>
    <div
        class="card card-flush"
        :class="{ 'card-bordered': view !== 'minimal' }"
    >
        <!--begin::Card header-->
        <div
            v-if="view !== 'minimal'"
            class="card-header align-items-center py-5 gap-2 gap-md-5"
        >
            <!--begin::Card title-->
            <div class="card-title">
                <!--begin::Search-->
                <div class="d-flex align-items-center position-relative my-1">
                    <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                    <span class="svg-icon svg-icon-1 position-absolute ms-4">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <rect
                                opacity="0.5"
                                x="17.0365"
                                y="15.1223"
                                width="8.15546"
                                height="2"
                                rx="1"
                                transform="rotate(45 17.0365 15.1223)"
                                fill="currentColor"
                            ></rect>
                            <path
                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </span>
                    <!--end::Svg Icon-->
                    <input
                        type="text"
                        v-model="search"
                        @input="onChangeSearch"
                        class="form-control form-control-solid w-250px ps-14"
                        :placeholder="__('Search Collection')"
                    />
                </div>
                <!--end::Search-->
            </div>
            <!--end::Card title-->
            <!--begin::Card toolbar-->
            <div class="card-toolbar flex-row-fluid justify-content-end gap-5">
                <!--begin::Add product-->
                <a
                    href="#"
                    @click.prevent="sendBulkDataToModal"
                    class="btn btn-danger"
                    :class="{ disabled: collectionIds.length === 0 }"
                >
                    {{ __('Delete') }}
                </a>
                <!--begin::Add product-->

                <div
                    v-tooltip="
                        totalCredits === 0
                            ? __('You do not have enough credits')
                            : ''
                    "
                    class="cursor-pointer"
                >
                    <Link
                        :href="route('image-generator-collection.new')"
                        class="btn btn-primary"
                        :class="{ disabled: totalCredits === 0 }"
                    >
                        {{ __('New') }}
                    </Link>
                </div>
            </div>
            <!--end::Card toolbar-->
        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div v-if="isLoading" class="card-body">
            <div class="d-flex align-items-center justify-content-center p-10">
                <div
                    class="spinner-border spinner-border text-primary"
                    role="status"
                >
                    <span class="visually-hidden">{{ __('Loading...') }}</span>
                </div>
            </div>
        </div>
        <div v-else class="card-body pt-0">
            <!--begin::Table-->
            <div
                id="collections_table_wrapper"
                class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
                <div class="table-responsive">
                    <table
                        class="table align-middle table-row-dashed fs-6 dataTable no-footer"
                        :class="{
                            'gy-3': view === 'minimal',
                            'gy-5': view !== 'minimal',
                        }"
                        id="collections_table"
                    >
                        <!--begin::Table head-->
                        <thead>
                            <!--begin::Table row-->
                            <tr
                                v-if="collections.length > 0"
                                class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
                            >
                                <th
                                    v-if="columns.includes('Checkbox')"
                                    class="w-10px pe-2 sorting_disabled"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label=""
                                >
                                    <div
                                        class="form-check form-check-sm form-check-custom form-check-solid me-3"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="allSelected"
                                            @change="selectAll"
                                        />
                                    </div>
                                </th>
                                <th
                                    v-if="columns.includes('Input')"
                                    class="min-w-200px sorting"
                                    tabindex="0"
                                    aria-controls="collections_table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Input"
                                >
                                    {{ __('Input') }}
                                </th>
                                <th
                                    v-if="columns.includes('Images')"
                                    class="min-w-200px sorting"
                                    tabindex="0"
                                    aria-controls="collections_table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Images"
                                >
                                    {{ __('Images') }}
                                </th>
                                <th
                                    v-if="columns.includes('Created At')"
                                    class="text-end min-w-100px sorting"
                                    tabindex="0"
                                    aria-controls="collections_table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Created At"
                                >
                                    {{ __('Created At') }}
                                </th>
                                <th
                                    v-if="columns.includes('Actions')"
                                    class="text-end min-w-70px sorting_disabled"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Actions"
                                >
                                    {{ __('Actions') }}
                                </th>
                                <th
                                    v-if="columns.includes('View')"
                                    class="text-center"
                                    tabindex="0"
                                    aria-controls="collections_table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="View"
                                ></th>
                            </tr>

                            <tr
                                v-else
                                class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
                            >
                                <th
                                    class="sorting_disabled"
                                    tabindex="0"
                                    aria-controls="documents_table"
                                    rowspan="1"
                                    colspan="1"
                                    :aria-label="__('Input')"
                                >
                                    {{ __('Input') }}
                                </th>
                            </tr>

                            <!--end::Table row-->
                        </thead>
                        <!--end::Table head-->
                        <!--begin::Table body-->
                        <tbody class="fw-bold text-gray-600">
                            <!--begin::Table row-->

                            <!--end::Table row-->
                            <tr
                                v-if="collections.length > 0"
                                v-for="(collection, index) in collections"
                            >
                                <!--begin::Checkbox-->
                                <td v-if="columns.includes('Checkbox')">
                                    <div
                                        class="form-check form-check-sm form-check-custom form-check-solid"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="collectionIds"
                                            @change="select"
                                            :value="collection.uuid"
                                        />
                                    </div>
                                </td>
                                <!--end::Checkbox-->
                                <!--begin::Category=-->
                                <td v-if="columns.includes('Input')">
                                    <div class="d-flex align-items-center">
                                        <!--begin::Title-->
                                        <Link
                                            :href="
                                                '/image-generator-collections/' +
                                                collection.uuid
                                            "
                                            class="text-gray-700 text-truncate"
                                        >
                                            {{ collection.input }}
                                        </Link>
                                        <!--end::Title-->
                                    </div>
                                </td>
                                <!--end::Category=-->
                                <!--begin::Image=-->
                                <td v-if="columns.includes('Images')">
                                    <div class="d-flex align-items-center">
                                        <!--begin::Images-->
                                        <div
                                            v-for="image in collection.images"
                                            class="symbol symbol symbol-45px me-5"
                                        >
                                            <Link
                                                :href="
                                                    '/image-generator-collections/' +
                                                    collection.uuid +
                                                    '/images/' +
                                                    image.uuid
                                                "
                                                class="text-gray-700 text-truncate"
                                            >
                                                <img
                                                    :src="image.thumbnail"
                                                    :alt="collection.input"
                                                />
                                            </Link>
                                        </div>
                                        <!--end::Images-->
                                    </div>
                                </td>
                                <!--end::Image=-->
                                <!--begin::Qty=-->
                                <td
                                    v-if="columns.includes('Created At')"
                                    class="text-end pe-0"
                                    data-order="41"
                                >
                                    {{
                                        moment(collection.created_at).format(
                                            'L LTS'
                                        )
                                    }}
                                </td>
                                <!--end::Qty=-->
                                <!--begin::Action=-->
                                <td v-if="columns.includes('Actions')" class="d-flex justify-content-end">
                                    <ActionsDropdown
                                        :modelValue="activeMenuIndex === index"
                                        @toggle="(isOpen) => handleMenuToggle(isOpen, index)"
                                    >
                                        <div class="menu-itempx-3">
                                            <Link
                                                :href="'/image-generator-collections/' + collection.uuid"
                                                class="menu-link text-start px-3"
                                            >
                                                {{ __('View') }}
                                            </Link>
                                        </div>
                                        <div class="menu-item px-3">
                                            <a
                                                href="#"
                                                class="menu-link text-start px-3"
                                                @click.prevent="sendDataToModal(collection.uuid)"
                                                data-bs-toggle="modal"
                                                data-bs-target="#kt_modal_delete"
                                            >
                                                {{ __('Delete') }}
                                            </a>
                                        </div>
                                    </ActionsDropdown>
                                </td>
                                <!--end::Action=-->
                                <td v-if="columns.includes('View')">
                                    <a
                                        :href="
                                            '/image-generator-collections/' +
                                            collection.uuid
                                        "
                                        class="btn btn-sm btn-icon w-30px h-30px"
                                    >
                                        <span
                                            class="svg-icon svg-icon-muted svg-icon-2"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="1em"
                                                viewBox="0 0 448 512"
                                            >
                                                <path
                                                    class="fa-primary"
                                                    opacity="0.4"
                                                    d="M312 144H160c-13.3 0-24 10.7-24 24s10.7 24 24 24h94.1L119 327c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l135-135V328c0 13.3 10.7 24 24 24s24-10.7 24-24V168c0-13.3-10.7-24-24-24z"
                                                />
                                                <path
                                                    class="fa-secondary"
                                                    d="M384 32c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H384zM160 144c-13.3 0-24 10.7-24 24s10.7 24 24 24h94.1L119 327c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l135-135V328c0 13.3 10.7 24 24 24s24-10.7 24-24V168c0-13.3-10.7-24-24-24H160z"
                                                />
                                            </svg>
                                        </span>
                                    </a>
                                </td>
                            </tr>

                            <tr v-else>
                                <td
                                    colspan="1"
                                    class="text-center text-gray-500"
                                >
                                    {{ __('No collections found.') }}
                                </td>
                            </tr>
                        </tbody>
                        <!--end::Table body-->
                    </table>
                </div>
                <div v-if="view !== 'minimal'" class="row">
                    <div
                        class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                        <div
                            class="dataTables_length"
                            id="collections_table_length"
                        >
                            <div class="d-flex flex-1 align-items-center">
                                <select
                                    class="form-select form-select-sm mw-70px me-4"
                                    v-model="selectedViewLengthOption"
                                    @change="onChangeViewLength"
                                >
                                    <option
                                        v-for="option in listViewLengthOptions"
                                        :value="option"
                                    >
                                        {{ option }}
                                    </option>
                                </select>
                                <label
                                    >{{ __('Showing') }} {{ from }}
                                    {{ __('to') }} {{ to }} {{ __('of') }}
                                    {{ total }} {{ __('entries') }}</label
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
                    >
                        <div
                            class="dataTables_paginate paging_simple_numbers"
                            id="collections_table_paginate"
                        >
                            <ul class="pagination">
                                <li
                                    v-for="(link, index) in pageLinks"
                                    class="page-item"
                                    :class="{
                                        'prev previous': index === 0,
                                        next: index === 14,
                                        active: link.label == currentPage,
                                    }"
                                >
                                    <a
                                        href="#"
                                        @click.prevent="
                                            fetchCollections(link.url)
                                        "
                                        class="page-link"
                                    >
                                        <span v-if="index === 0"
                                            ><i class="previous"></i
                                        ></span>
                                        <span
                                            v-else-if="
                                                index ===
                                                Object.keys(pageLinks).length -
                                                    1
                                            "
                                            ><i class="next"></i
                                        ></span>
                                        <span v-else>{{ link.label }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="view === 'minimal'" class="text-end">
                <Link :href="route('image-generator-collections.list')">
                    {{ __('All Collections') }}
                </Link>
            </div>
            <!--end::Table-->
        </div>
        <!--end::Card body-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Link } from '@inertiajs/vue3';
import AppLayout from '@/Layouts/AppLayout.vue';
import Multiselect from '@vueform/multiselect';
import ActionsDropdown from '@/Components/Common/Table/ActionsDropdown.vue';
import moment from 'moment/moment';

export default defineComponent({
    components: {
        Link,
        AppLayout,
        Multiselect,
        ActionsDropdown,
    },

    props: {
        view: {
            type: String,
            default: 'default',
        },
        columns: {
            type: Array,
            default: () => [
                'Checkbox',
                'Input',
                'Images',
                'Created At',
                'Actions',
            ],
        },
        initialSelectedViewLengthOption: {
            type: Number,
            default: 10,
        },
        totalCredits: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            search: '',
            collectionsUrl: '/api/image-generator-collections',
            collections: {},
            copyClicked: false,
            currentPage: 1,
            firstPageUrl: '',
            from: 1,
            to: 1,
            total: 1,
            lastPage: 1,
            lastPageUrl: '',
            nextPageUrl: '',
            prevPageUrl: '',
            pageLinks: {},
            selected: [],
            allSelected: false,
            selectedViewLengthOption: this.initialSelectedViewLengthOption,
            listViewLengthOptions: [10, 25, 50, 100, 500],
            collectionIds: [],
            currentMenu: {
                open: false,
                element: {},
            },
            moment: moment,
            isLoading: true,
            activeMenuIndex: null,
        };
    },

    mounted() {
        this.fetchCollections(this.collectionsUrl);
    },

    methods: {
        sendDataToModal(uuid) {
            emitter.emit('set-delete-candidate', {
                uuid: uuid,
                url: this.collectionsUrl,
                title: this.__('Are you sure?'),
                description: this.__(
                    'Do you really want to delete this collection? This procedure is irreversible.'
                ),
                callback: this.deleteCallback,
            });
            this.$page.props.deleteCandidate = uuid;
        },

        sendBulkDataToModal() {
            emitter.emit('set-delete-candidate', {
                ids: this.collectionIds,
                url: this.collectionsUrl,
                title: this.__('Are you sure?'),
                description: this.__(
                    'Do you really want to delete this collection? This procedure is irreversible.'
                ),
                callback: this.deleteCallback,
            });
        },

        deleteCallback(uuid) {
            this.fetchCollections(this.collectionsUrl);
        },

        fetchCollections(url) {
            if (url) {
                this.isLoading = true;

                let filterParams =
                    'per-page=' +
                    this.selectedViewLengthOption +
                    '&search=' +
                    this.search;
                let requestUrl = url.includes('page')
                    ? url + '&' + filterParams
                    : url + '?' + filterParams;

                axios
                    .get(requestUrl)
                    .then((response) => {
                        this.collections = response.data.data;
                        this.currentPage = response.data.current_page;
                        this.firstPageUrl = response.data.first_page_url;
                        this.from = response.data.from;
                        this.to = response.data.to;
                        this.total = response.data.total;
                        this.lastPage = response.data.last_page;
                        this.lastPageUrl = response.data.last_page_url;
                        this.nextPageUrl = response.data.next_page_url;
                        this.prevPageUrl = response.data.prev_page_url;
                        this.pageLinks = response.data.links;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },

        onChangeSearch(ev) {
            this.fetchCollections(this.collectionsUrl);
        },

        selectAll() {
            let vm = this;
            vm.collectionIds = [];
            if (this.allSelected) {
                this.collections.forEach(function (collection) {
                    vm.collectionIds.push(collection.uuid);
                });
            }
        },

        select() {
            this.allSelected = false;
        },

        handleMenuToggle(isOpen, index) {
            this.activeMenuIndex = isOpen ? index : null;
        },
    },
    computed: {},
});
</script>

<style lang="scss">
.action-dropdown-menu {
    .menu-sub-dropdown {
        top: 51px;
        right: 20px;
    }
}
</style>
