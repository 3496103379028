<template>
    <!--begin::Wrapper-->
    <div class="w-100">
        <!--begin::Heading-->
        <div class="pb-10 pb-lg-15">
            <!--begin::Title-->
            <h2 class="fw-bolder text-dark">
                {{ __('Brand Identity') }}
            </h2>
            <!--end::Title-->
        </div>
        <!--end::Heading-->

        <div
            v-if="onboardProcessStore.loading"
            class="d-flex justify-content-center pb-5 my-20"
            :class="{
                'd-flex': onboardProcessStore.loading,
                'd-none': !onboardProcessStore.loading,
            }"
        >
            <CreaitorLoading
                :loading="onboardProcessStore.getLoading"
                :loading-text="loadingText"
            ></CreaitorLoading>
        </div>

        <div
            v-else-if="onboardProcessStore.brandIdentityDetectionTookTooLong"
            class="d-flex justify-content-center pb-5 my-20"
        >
            <div class="mb-10 fv-row">
                <div class="col-12">
                    <!--begin::Alert-->
                    <div
                        class="alert alert-primary d-flex align-items-center p-10"
                    >
                        <!--begin::Wrapper-->
                        <div class="d-flex flex-column">
                            <!--begin::Title-->
                            <h4 class="mb-1 text-dark">
                                {{
                                    __(
                                        'Brand identity detection takes longer then expected'
                                    )
                                }}
                            </h4>
                            <!--end::Title-->

                            <!--begin::Content-->
                            <span class="fs-6 mt-2">{{
                                __(
                                    "We let it run in the background and let you know when it's ready."
                                )
                            }}</span>
                            <span class="fs-6">{{
                                __('You can already use Creaitor now.')
                            }}</span>
                            <!--end::Content-->
                        </div>
                        <!--end::Wrapper-->
                    </div>
                    <!--end::Alert-->
                </div>
            </div>
        </div>

        <div v-else>
            <!--begin::Input group-->
            <div class="mb-10 fv-row">
                <!--begin::Label-->
                <label
                    class="d-flex align-items-center form-label mb-3 required"
                >
                    {{ __('Brand Name') }}
                    <i
                        class="fas fa-exclamation-circle ms-2 fs-7"
                        data-bs-toggle="tooltip"
                        :title="__('Brand Name')"
                    ></i>
                </label>
                <!--end::Label-->
                <!--begin::Input-->
                <input
                    type="text"
                    v-model="brandName"
                    class="form-control form-control-lg"
                    :class="{ 'is-invalid': isInvalid('brand_name') }"
                    name="brand_name"
                    :placeholder="__('Brand Name')"
                />
                <!--end::Input-->
                <FormError
                    :message="
                        onboardProcessStore.getFormErrors?.brand_voice
                            ?.brand_name
                    "
                ></FormError>
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="mb-10 fv-row">
                <!--begin::Label-->
                <label
                    class="d-flex align-items-center form-label mb-3 required"
                >
                    {{ __('Brand Purpose') }}
                    <i
                        class="fas fa-exclamation-circle ms-2 fs-7"
                        data-bs-toggle="tooltip"
                        :title="__('Brand Purpose')"
                    ></i>
                </label>
                <!--end::Label-->
                <!--begin::Input-->
                <textarea
                    name="purpose"
                    class="form-control form-control-lg"
                    :class="{ 'is-invalid': isInvalid('purpose') }"
                    rows="3"
                    :placeholder="__('Brand Purpose')"
                    v-model="purpose"
                ></textarea>
                <!--end::Input-->
                <FormError
                    :message="
                        onboardProcessStore.getFormErrors?.brand_voice?.purpose
                    "
                ></FormError>
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="mb-10 fv-row">
                <!--begin::Label-->
                <label
                    class="d-flex align-items-center form-label mb-3 required"
                >
                    {{ __('Brand Overview') }}
                    <i
                        class="fas fa-exclamation-circle ms-2 fs-7"
                        data-bs-toggle="tooltip"
                        :title="__('Brand Overview')"
                    ></i>
                </label>
                <!--end::Label-->
                <!--begin::Input-->
                <textarea
                    name="description"
                    class="form-control form-control-lg"
                    :class="{ 'is-invalid': isInvalid('description') }"
                    rows="3"
                    :placeholder="__('Brand Overview')"
                    v-model="description"
                ></textarea>
                <!--end::Input-->
                <FormError
                    :message="
                        onboardProcessStore.getFormErrors?.brand_voice
                            ?.description
                    "
                >
                </FormError>
                <div
                    class="d-flex text-gray-600"
                    :class="{
                        'justify-content-between':
                            descriptionCharCount > 0 &&
                            descriptionCharCount < 40,
                        'justify-content-end':
                            descriptionCharCount === 0 ||
                            descriptionCharCount >= 40,
                    }"
                >
                    <div
                        class="align-self-start text-warning"
                        :class="{
                            'd-none':
                                descriptionCharCount === 0 ||
                                descriptionCharCount >= 40,
                        }"
                    >
                        <span class="fs-8 fw-bolder"
                            ><i
                                class="bi bi-exclamation-triangle-fill text-warning me-1"
                            ></i>
                            {{
                                __('Too Short. Write more for a better result.')
                            }}
                        </span>
                    </div>
                    <div
                        class="align-self-end"
                        :class="{
                            'text-warning':
                                descriptionCharCount > 0 &&
                                descriptionCharCount < 40,
                        }"
                    >
                        <span>{{ descriptionCharCount }}</span>
                        /1000
                    </div>
                </div>
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="fv-row mb-10">
                <!--begin::Label-->
                <label class="form-label required">
                    {{ __('Tone Selection') }}
                </label>
                <!--end::Label-->
                <!--begin::Input-->
                <Multiselect
                    v-model="toneSelection"
                    mode="tags"
                    :placeholder="__('Tone Selection')"
                    track-by="name"
                    label="name"
                    class="p-0"
                    :close-on-select="false"
                    :searchable="true"
                    :options="tones"
                    :class="{ 'is-invalid': isInvalid('tone') }"
                />
                <!--end::Input-->
                <FormError
                    :message="
                        onboardProcessStore.getFormErrors?.brand_voice?.tone
                    "
                ></FormError>
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="mb-10 fv-row">
                <!--begin::Label-->
                <label class="d-flex align-items-center form-label mb-3">
                    {{ __('Target Audience') }}
                    <i
                        class="fas fa-exclamation-circle ms-2 fs-7"
                        data-bs-toggle="tooltip"
                        :title="__('Target Audience')"
                    ></i>
                </label>
                <!--end::Label-->
                <!--begin::Input-->
                <input
                    type="text"
                    v-model="targetAudience"
                    class="form-control form-control-lg"
                    name="target_udience"
                    :placeholder="__('Target Audience')"
                />
                <!--end::Input-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div
                class="mb-10 fv-row"
                v-for="slider in sliders"
                :key="slider.type"
            >
                <!--begin::Label-->
                <label class="d-flex align-items-center form-label mb-3">
                    {{ slider.label }}
                    <i
                        class="fas fa-exclamation-circle ms-2 fs-7"
                        data-bs-toggle="tooltip"
                        :title="slider.label"
                    ></i>
                </label>
                <!--end::Label-->
                <BrandIdentitySlider
                    :sliderSettings="slider.sliderSettings"
                    @output-change="onOutputChange($event, slider.type)"
                ></BrandIdentitySlider>
            </div>
            <!--end::Input group-->
        </div>
    </div>
    <!--end::Wrapper-->
</template>

<script>
import { defineComponent } from 'vue';
import { useOnboardProcessStore } from '@/Stores/OnboardProcessStore';
import CreaitorLoading from '@/Components/Loading/CreaitorLoading.vue';
import BrandIdentitySlider from '@/Pages/Onboard/Partials/BrandIdentitySlider.vue';
import FormError from '@/Components/Form/Error.vue';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
    components: {
        CreaitorLoading,
        BrandIdentitySlider,
        FormError,
        Multiselect,
    },

    setup() {
        // Get onboardProcess store
        const onboardProcessStore = useOnboardProcessStore();

        // Make it available inside methods
        return {
            onboardProcessStore,
        };
    },

    props: {
        loadingText: {
            type: String,
        },
    },

    data() {
        return {
            sliders: [
                {
                    label: this.__('Emotional Tone'),
                    sliderSettings: {
                        pips: {
                            labels: {
                                1: this.__('Formal/Reserved'),
                                100: this.__('Emotional/Expressive'),
                            },
                        },
                        start:
                            this.onboardProcessStore.getBrandIdentity
                                ?.emotional_tone || 50,
                    },
                    type: 'emotional_tone',
                },
                {
                    label: this.__('Humor Intensity'),
                    sliderSettings: {
                        pips: {
                            labels: {
                                1: this.__('Serious'),
                                100: this.__('Humorous'),
                            },
                        },
                        start:
                            this.onboardProcessStore.getBrandIdentity
                                ?.humor_intensity || 50,
                    },
                    type: 'humor_intensity',
                },
                {
                    label: this.__('Personalization'),
                    sliderSettings: {
                        pips: {
                            labels: {
                                1: this.__('Impersonal'),
                                100: this.__('Personal'),
                            },
                        },
                        start:
                            this.onboardProcessStore.getBrandIdentity
                                ?.personalization || 50,
                    },
                    type: 'personalization',
                },
                {
                    label: this.__('Language Style'),
                    sliderSettings: {
                        pips: {
                            labels: {
                                1: this.__('Modern'),
                                100: this.__('Classic'),
                            },
                        },
                        start:
                            this.onboardProcessStore.getBrandIdentity
                                ?.language_style || 50,
                    },
                    type: 'language_style',
                },
                {
                    label: this.__('Content Type'),
                    sliderSettings: {
                        pips: {
                            labels: {
                                1: this.__('Fact-based'),
                                100: this.__('Opinion-based'),
                            },
                        },
                        start:
                            this.onboardProcessStore.getBrandIdentity
                                ?.content_type || 50,
                    },
                    type: 'content_type',
                },
            ],
        };
    },

    computed: {
        brandName: {
            get() {
                return this.onboardProcessStore.getBrandIdentity?.brand_name || '';
            },
            set(value) {
                this.onboardProcessStore.setBrandIdentity('brand_name', value);
            },
        },
        purpose: {
            get() {
                return this.onboardProcessStore.getBrandIdentity?.purpose || '';
            },
            set(value) {
                this.resetFormErrors('purpose');
                this.onboardProcessStore.setBrandIdentity('purpose', value);
            },
        },
        description: {
            get() {
                return this.onboardProcessStore.getBrandIdentity?.description || '';
            },
            set(value) {
                this.resetFormErrors('description');
                this.onboardProcessStore.setBrandIdentity('description', value);
            },
        },
        targetAudience: {
            get() {
                return this.onboardProcessStore.getBrandIdentity?.target_audience || '';
            },
            set(value) {
                this.onboardProcessStore.setBrandIdentity('target_audience', value);
            },
        },
        toneSelection: {
            get() {
                const tone =
                    this.onboardProcessStore?.getBrandIdentity?.tone || [];

                return Array.isArray(tone) ? tone : [];
            },
            set(value) {
                this.onboardProcessStore.setBrandIdentity('tone', value);
            },
        },
        descriptionCharCount() {
            return this.description?.length || 0;
        },
        tones() {
            return this.onboardProcessStore.getAvailableTones?.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            });
        },
    },


    methods: {
        onOutputChange(value, sliderType) {
            this.onboardProcessStore.setBrandIdentity(sliderType, value);
        },
        isInvalid(field) {
            return (
                this.onboardProcessStore.getFormErrors?.brand_voice[field]
                    .length > 0
            );
        },
        resetFormErrors(field) {
            this.onboardProcessStore.setFormErrors(`brand_voice.${field}`, []);
        },
        updateSliders() {
            this.sliders.forEach((slider) => {
                let newValue =
                    this.onboardProcessStore.getBrandIdentity[slider.type];
                if (newValue != null) {
                    slider.sliderSettings.start = newValue;
                }
            });
        },
    },

    watch: {
        brandName(newVal, oldVal) {
            console.log('brandName changed from', oldVal, 'to', newVal);
        },
        'onboardProcessStore.getBrandIdentity': {
            handler(newVal, oldVal) {
                this.updateSliders();
                console.log(
                    'Brand Identity changed from',
                    oldVal,
                    'to',
                    newVal
                );
            },
            deep: true, // Watch nested properties of the object as well
        },
    },
});
</script>
<style lang="scss">
#kt_create_onboard_process_stepper {
    .multiselect {
        min-height: calc(1.5em + 1.65rem + 2px);
    }
}
</style>
