<template>
    <div class="card card-flush card-bordered">
        <!--begin::Card header-->
        <div class="card-header align-items-center py-5 gap-2 gap-md-5">
            <!--begin::Card title-->
            <div class="card-title">
                <!--begin::Search-->
                <div class="d-flex align-items-center position-relative my-1">
                    <span class="svg-icon svg-icon-1 position-absolute ms-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                            <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                        </svg>
                    </span>
                    <input
                        type="text"
                        v-model="search"
                        @input="onChangeSearch"
                        class="form-control form-control-solid w-250px ps-14"
                        :placeholder="__('Search File')"
                    />
                </div>
                <!--end::Search-->
            </div>
            <!--end::Card title-->
            <!--begin::Card toolbar-->
            <div class="card-toolbar flex-row-fluid justify-content-end gap-5">
                <a href="#" @click.prevent="sendBulkDataToModal" class="btn btn-danger">
                    {{ __('Delete') }}
                </a>
                <a href="#" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_bulk_processing">
                    {{ __('New') }}
                </a>
            </div>
            <!--end::Card toolbar-->
        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div class="card-body pt-0">
            <!--begin::Table-->
            <div id="files_table_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="table-responsive">
                    <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="files_table">
                        <!--begin::Table head-->
                        <thead>
                            <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                <th class="w-10px pe-2 sorting_disabled">
                                    <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="allSelected"
                                            @change="selectAll"
                                        />
                                    </div>
                                </th>
                                <th class="min-w-200px sorting">{{ __('Name') }}</th>
                                <th class="text-end min-w-100px sorting">{{ __('Updated At') }}</th>
                                <th class="text-end min-w-100px sorting">{{ __('Created At') }}</th>
                                <th class="text-end min-w-70px sorting_disabled">{{ __('Actions') }}</th>
                            </tr>
                        </thead>
                        <!--end::Table head-->
                        <!--begin::Table body-->
                        <tbody class="fw-bold text-gray-600">
                            <tr v-for="(file, index) in files" :key="file.uuid">
                                <td>
                                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="fileIds"
                                            @change="select"
                                            :value="file.uuid"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span v-if="file.progress < 100">{{ file.name }}</span>
                                        <a v-else :href="'/bulk/' + file.uuid" class="text-gray-700" target="_blank">
                                            {{ file.name }}
                                        </a>
                                    </div>
                                </td>
                                <td class="text-end pe-0">
                                    {{ moment(file.updated_at).format('L LTS') }}
                                </td>
                                <td class="text-end pe-0">
                                    {{ moment(file.created_at).format('L LTS') }}
                                </td>
                                <td class="d-flex justify-content-end">
                                    <div v-if="file.progress < 100" class="progress">
                                        <div
                                            class="progress-bar progress-bar-striped progress-bar-animated"
                                            role="progressbar"
                                            :style="{ width: file.progress + '%' }"
                                            :aria-valuenow="file.progress"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            {{ file.progress }}%
                                        </div>
                                    </div>
                                    <ActionsDropdown
                                        v-else
                                        :modelValue="activeMenuIndex === index"
                                        @toggle="(isOpen) => handleMenuToggle(isOpen, index)"
                                    >
                                        <div class="menu-item px-3">
                                            <a :href="'/bulk/' + file.uuid" class="menu-link px-3" target="_blank">
                                                {{ __('Download') }}
                                            </a>
                                        </div>
                                        <div class="menu-item text-start px-3">
                                            <a
                                                href="#"
                                                class="menu-link text-start px-3"
                                                @click.prevent="sendDataToModal(file.uuid)"
                                                data-bs-toggle="modal"
                                                data-bs-target="#kt_modal_delete"
                                            >
                                                {{ __('Delete') }}
                                            </a>
                                        </div>
                                    </ActionsDropdown>
                                </td>
                            </tr>
                        </tbody>
                        <!--end::Table body-->
                    </table>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                        <div class="dataTables_length" id="files_table_length">
                            <label>
                                {{ __('Showing') }} {{ from }} {{ __('to') }} {{ to }} {{ __('of') }}
                                {{ total }} {{ __('entries') }}
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                        <div class="dataTables_paginate paging_simple_numbers" id="files_table_paginate">
                            <ul class="pagination">
                                <li
                                    v-for="(link, index) in pageLinks"
                                    :key="index"
                                    class="page-item"
                                    :class="{
                                        'prev previous': index === 0,
                                        next: index === Object.keys(pageLinks).length - 1,
                                        active: link.label == currentPage,
                                    }"
                                >
                                    <a href="#" @click.prevent="fetchFiles(link.url)" class="page-link">
                                        <span v-if="index === 0"><i class="previous"></i></span>
                                        <span v-else-if="index === Object.keys(pageLinks).length - 1">
                                            <i class="next"></i>
                                        </span>
                                        <span v-else>{{ link.label }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!--end::Table-->
        </div>
        <!--end::Card body-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Link } from '@inertiajs/vue3';
import ActionsDropdown from '@/Components/Common/Table/ActionsDropdown.vue';
import moment from 'moment/moment';

export default defineComponent({
    components: {
        Link,
        ActionsDropdown,
    },

    data() {
        return {
            search: '',
            filesUrl: '/api/bulk-processings',
            files: {},
            currentPage: 1,
            firstPageUrl: '',
            from: 1,
            to: 1,
            total: 1,
            lastPage: 1,
            lastPageUrl: '',
            nextPageUrl: '',
            prevPageUrl: '',
            pageLinks: {},
            selected: [],
            allSelected: false,
            fileIds: [],
            moment: moment,
            inProgress: false,
            progressInterval: null,
            activeMenuIndex: null,
        };
    },

    mounted() {
        this.fetchFiles(this.filesUrl);
        this.checkProgress();

        emitter.on('new-bulk-processing', () => {
            this.fetchFiles(this.filesUrl);
            this.checkProgress();
        });
    },

    beforeUnmount() {
        emitter.off('new-bulk-processing');
        clearInterval(this.progressInterval);
    },

    methods: {
        checkProgress() {
            clearInterval(this.progressInterval);
            this.progressInterval = setInterval(() => {
                this.fetchFiles(this.filesUrl);
                this.files.forEach((file) => {
                    if (file.progress < 100) {
                        this.inProgress = true;
                    }
                });
                if (!this.inProgress) {
                    clearInterval(this.progressInterval);
                }
                this.inProgress = false;
            }, 5000);
        },

        sendDataToModal(uuid) {
            emitter.emit('set-delete-candidate', {
                uuid: uuid,
                url: this.filesUrl,
                title: this.__('Are you sure?'),
                description: this.__('Do you really want to delete this file? This procedure is irreversible.'),
                callback: this.deleteCallback,
            });
        },

        sendBulkDataToModal() {
            emitter.emit('set-delete-candidate', {
                ids: this.fileIds,
                url: this.filesUrl,
                title: this.__('Are you sure?'),
                description: this.__('Do you really want to delete this file? This procedure is irreversible.'),
                callback: this.deleteCallback,
            });
        },

        deleteCallback() {
            this.fetchFiles(this.filesUrl);
        },

        fetchFiles(url) {
            if (url) {
                let filterParams = 'search=' + this.search;
                let requestUrl = url.includes('page')
                    ? url + '&' + filterParams
                    : url + '?' + filterParams;

                axios.get(requestUrl).then((response) => {
                    this.files = response.data.data;
                    this.currentPage = response.data.current_page;
                    this.firstPageUrl = response.data.first_page_url;
                    this.from = response.data.from;
                    this.to = response.data.to;
                    this.total = response.data.total;
                    this.lastPage = response.data.last_page;
                    this.lastPageUrl = response.data.last_page_url;
                    this.nextPageUrl = response.data.next_page_url;
                    this.prevPageUrl = response.data.prev_page_url;
                    this.pageLinks = response.data.links;
                });
            }
        },

        onChangeSearch() {
            this.fetchFiles(this.filesUrl);
        },

        selectAll() {
            this.fileIds = [];
            if (this.allSelected) {
                this.files.forEach((file) => {
                    this.fileIds.push(file.uuid);
                });
            }
        },

        select() {
            this.allSelected = false;
        },

        handleMenuToggle(isOpen, index) {
            this.activeMenuIndex = isOpen ? index : null;
        },
    },
});
</script>
